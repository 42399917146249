
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Circles } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from '@mui/material/styles';

import * as service from '../../services/service';

function Reports() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [status, setStatus] = useState('');
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [showSummaryReport, setShowSummaryReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const statusData = [{ label: "All", value: "All" }, { label: "Pending", value: "Pending" }, { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" }, { label: "Visited", value: "Visited" }, { label: "Cancelled", value: "Cancelled" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setCountryData(data2);
                setCountryId(all);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setStateData(data2);
                setStateId(all);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const st = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).stateId);
                    setStateId(st);
                }
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));

                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setDistrictData(data2);
                setDistrictId(all);

                if (JSON.parse(sessionStorage.getItem('userData')).userRole !== 'Global') {
                    const dt = data2.find(x => x.value === JSON.parse(sessionStorage.getItem('userData')).districtId);
                    setDistrictId(dt);
                }
            });
    };

    useEffect(() => {
        getCountries();

        // const def = { value: 1, label: 'India' };
        // setCountryId(def);
        // getStates(def.value);

        const st = { value: 'All', label: 'All' };
        setStatus(st);
    }, []);

    // const validate = () => {
    //     let isValid = true;
    //     const errs = {};
    //     if (!stateId) {
    //         isValid = false;
    //         errs[".state"] = "Please select state";
    //     }
    //     setErrors(errs);
    //     return isValid;
    // }

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateData(null);
        setDistrictData(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        // setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const statusChangeHandler = (ev) => {
        setStatus(ev);
    }

    const handleChange = (event) => {
        setShowDetailsReport(false);
        setShowSummaryReport(false);
        setReportType(event.target.value);
    };

    function GetVisiterDetailsReport() {
        const errs = {};
        fetch(
            service.GET_VISITER_DETAILS_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null,
                    Status: status.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setShowSummaryReport(false);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setShowSummaryReport(false);
                }
            });
    }

    function GetMonthWiseSummaryReport() {
        const errs = {};
        fetch(
            service.GET_MONTH_WISE_SUMMARY_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: countryId.value,
                    StateId: stateId.value ? stateId.value : 0,
                    DistrictId: districtId.value ? districtId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null,
                    Status: status.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setShowSummaryReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(false);
                    setShowSummaryReport(true);
                    setSummaryData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setShowSummaryReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                    setShowSummaryReport(false);
                }
            });
    }

    const submitHandler = e => {
        setShowDetailsReport(false);
        setShowSummaryReport(false);
        e.preventDefault();
        setLoading(true);
        if (!reportType) {
            // eslint-disable-next-line
            alert("Please select report type");
        }
        if (reportType === "VisiterDetails") {
            GetVisiterDetailsReport();
        }
        else if (reportType === "MonthWiseSummaryReport") {
            GetMonthWiseSummaryReport();
        }
        setLoading(false);
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    const ShowDetailsReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvDetailReport">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Address</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>AdharCardNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PassportNo</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Mobile No</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Country</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>State</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Arrival Date</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Departure Date</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Center</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Coordinator</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Visiter Type</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Email Id</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Birth Date</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Self Realization Year</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Donation</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Group Leader</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Room</TableCell>
                    </TableRow>
                    {reportDetailsData.map((row) => (
                        <TableRow
                            key={row.VISITERId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.address}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.adharCardNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.passportNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.country}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.state}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{moment(row.arrivalDate).format("DD/MM/YYYY")} {row.arrivalTime}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{moment(row.departureDate).format("DD/MM/YYYY")} {row.departureTime}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.center}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.centerCoordinatorName}, {row.centerCoordinatorContactNo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.visiterType}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.emailId}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{moment(row.birthDate).format("DD/MM/YYYY")}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.selfRealizationYear}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.contribution}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.groupLeaderName}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.roomName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));


    const ShowSummaryReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvSummaryReport" border="1">
                <TableBody>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Month-Year</TableCell>
                        <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Adults</TableCell>
                        <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Yuvashakti</TableCell>
                        <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Balshakti</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Count</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Donation</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell />
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Male</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Female</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Male</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Female</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Male</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Female</TableCell>
                        <TableCell  align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                    {summaryData.map((row) => (
                        <TableRow
                            key={row.monthYear}
                            sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                        >
                            <TableCell  align="center" style={{ fontSize: '14px' }}>{row.monthYear}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.adultMaleCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.adultFemaleCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.adultTotalCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.yuvaMaleCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.yuvaFemaleCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.yuvaTotalCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.balMaleCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.balFemaleCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.balTotalCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.totalCount}</TableCell>
                            <TableCell align='right' style={{ fontSize: '14px' }}>{row.totalAmount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Reports
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Visiter Details
                                                    <Radio
                                                        checked={reportType === 'VisiterDetails'}
                                                        onChange={handleChange}
                                                        value="VisiterDetails"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Visiter Details"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Month Wise Summary Report
                                                    <Radio
                                                        checked={reportType === 'MonthWiseSummaryReport'}
                                                        onChange={handleChange}
                                                        value="MonthWiseSummaryReport"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Month Wise Summary Report"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '25%', fontSize: '14px' }}>
                                                    <Dropdown menuClassName='myMenuClassName' id="country"
                                                        name="country"
                                                        options={countryData}
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        label="Country"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '20%', fontSize: '14px' }}>
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px' }}>
                                                    <Select className="basic-single"
                                                        options={statusData}
                                                        name="status"
                                                        value={status}
                                                        onChange={statusChangeHandler}
                                                        isSearchable
                                                        placeholder="Select Status"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".status"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    From Date
                                                    <DatePicker
                                                        id="fromDate"
                                                        selected={fromDate}
                                                        onChange={date => setFromDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    To Date
                                                    <DatePicker
                                                        id="toDate"
                                                        selected={toDate}
                                                        onChange={date => setToDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        {loading ?
                                                            <Circles
                                                                heigth="20"
                                                                width="20"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            />

                                                            :
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                Show
                                                            </MDButton>
                                                        }
                                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {showDetailsReport ? <ShowDetailsReport /> : null}
                                                {showSummaryReport ? <ShowSummaryReport /> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">
                                                {showDetailsReport ?
                                                    <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                                {showSummaryReport ?
                                                    <ReactHTMLTableToExcel table="dvSummaryReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reports;
