
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import Select from "react-select";
import * as service from '../../../services/service';

function AddVisiter() {
    const [state, setState] = useState(
        {
            firstname: '', middlename: '', lastname: '', center: '', emailid: '', mobileno: '', passportno: '', pan: '', adharcardno: '',
            coordinatorname: '', coordinatorcontact: '', address: '', otherdistrict: ''
        });
    const [birthDate, setBirthDate] = useState(new Date());
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [departureDate, setDepartureDate] = useState(new Date());
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const [arrivalTime, setArrivalTime] = useState('');
    const [departureTime, setDepartureTime] = useState('');
    const [selfRealizationYear, setSelfRealizationYear] = useState('');
    const [visiterType, setVisiterType] = useState('');
    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    const visterTypeData = [{ label: "Adult", value: "Adult" }, { label: "Yuva", value: "Yuvashakti" }, { label: "Bal", value: "Balshakti" }];
    const yearList = [
        { label: "2023", value: "2023" }, { label: "2022", value: "2022" },
        { label: "2021", value: "2021" }, { label: "2020", value: "2020" },
        { label: "2019", value: "2019" }, { label: "2018", value: "2018" },
        { label: "2017", value: "2017" }, { label: "2016", value: "2016" },
        { label: "2015", value: "2015" }, { label: "2014", value: "2014" },
        { label: "2013", value: "2013" }, { label: "2012", value: "2012" },
        { label: "2011", value: "2011" }, { label: "2010", value: "2010" },
        { label: "2009", value: "2009" }, { label: "2008", value: "2008" },
        { label: "2007", value: "2007" }, { label: "2006", value: "2006" },
        { label: "2005", value: "2005" }, { label: "2004", value: "2004" },
        { label: "2003", value: "2003" }, { label: "2002", value: "2002" },
        { label: "2001", value: "2001" }, { label: "2000", value: "2000" },
        { label: "1999", value: "1999" },
        { label: "1998", value: "1998" }, { label: "1997", value: "1997" },
        { label: "1996", value: "1996" }, { label: "1995", value: "1995" },
        { label: "1994", value: "1994" }, { label: "1993", value: "1993" },
        { label: "1992", value: "1992" }, { label: "1991", value: "1991" },
        { label: "1990", value: "1990" }, { label: "1989", value: "1989" },
        { label: "1988", value: "1988" }, { label: "1987", value: "1987" },
        { label: "1986", value: "1986" }, { label: "1985", value: "1985" },
        { label: "1984", value: "1984" }, { label: "1983", value: "1983" }];

    const timeData = [
        { label: "4 AM", value: "4 AM" }, { label: "4.30 AM", value: "4.30 AM" },
        { label: "5 AM", value: "5 AM" }, { label: "5.30 AM", value: "5.30 AM" },
        { label: "6 AM", value: "6 AM" }, { label: "6.30 AM", value: "6.30 AM" },
        { label: "7 AM", value: "7 AM" }, { label: "7.30 AM", value: "7.30 AM" },
        { label: "8 AM", value: "8 AM" }, { label: "8.30 AM", value: "8.30 AM" },
        { label: "9 AM", value: "9 AM" }, { label: "9.30 AM", value: "9.30 AM" },
        { label: "10 AM", value: "10 AM" }, { label: "10.30 AM", value: "10.30 AM" },
        { label: "11 AM", value: "11 AM" }, { label: "11.30 AM", value: "11.30 AM" },
        { label: "12 PM", value: "12 PM" }, { label: "12.30 PM", value: "12.30 PM" },
        { label: "1 PM", value: "1 PM" }, { label: "1.30 PM", value: "1.30 PM" },
        { label: "2 PM", value: "2 PM" }, { label: "2.30 PM", value: "2.30 PM" },
        { label: "3 PM", value: "3 PM" }, { label: "3.30 PM", value: "3.30 PM" },
        { label: "4 PM", value: "4 PM" }, { label: "4.30 PM", value: "4.30 PM" },
        { label: "5 PM", value: "5 PM" }, { label: "5.30 PM", value: "5.30 PM" },
        { label: "6 PM", value: "6 PM" }, { label: "6.30 PM", value: "6.30 PM" },
        { label: "7 PM", value: "7 PM" }, { label: "7.30 PM", value: "7.30 PM" },
        { label: "8 PM", value: "8 PM" }, { label: "8.30 PM", value: "8.30 PM" },
        { label: "9 PM", value: "9 PM" }, { label: "9.30 PM", value: "9.30 PM" },
        { label: "10 PM", value: "10 PM" }, { label: "10.30 PM", value: "10.30 PM" },
        { label: "11 PM", value: "11 PM" }, { label: "11.30 PM", value: "11.30 PM" },
        { label: "12 AM", value: "12 AM" }, { label: "12.30 AM", value: "12.30 AM" }];

    const [otherVisiters, setOtherVisiters] = useState([]);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const params = useParams();
    const navigate = useNavigate();

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_VISITER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VISITERId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, firstname: result.firstName, middlename: result.middleName, lastname: result.lastName,
                            center: result.center, pan: result.pan, couponno: result.couponNo
                        });
                        // const conData = ({ value: result.countryId, label: result.country });
                        // setCountryId(conData);
                        // getStates(conData.value);
                        // const stData = ({ value: result.stateId, label: result.state });
                        // setStateId(stData);
                        // getDistricts(stData.value);
                        // const disData = ({ value: result.districtId, label: result.district });
                        // setDistrictId(disData);
                        const gen = ({ value: result.gender, label: result.gender });
                        setGender(gen);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getCountries();
        if (params.id > 0) {
            getDetailsById();
        }
        else {
            const def = { value: 1, label: 'India' };
            setCountryId(def);
            getStates(def.value);

            const gen = { label: 'Male', value: 'Male' };
            setGender(gen);
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.firstname) {
            isValid = false;
            errs[".firstname"] = "Enter first name";
        }
        if (!state.lastname) {
            isValid = false;
            errs[".lastname"] = "Enter last name";
        }
        if (!state.address) {
            isValid = false;
            errs[".address"] = "Enter address";
        }
        if (!visiterType) {
            isValid = false;
            errs[".visiterType"] = "Select type";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Select state";
        }
        if (!districtId && !state.otherdistrict) {
            isValid = false;
            errs[".district"] = "Please select district or enter district if its not present in the list";
        }
        if (!state.emailid) {
            isValid = false;
            errs[".emailId"] = "Enter emailId";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Enter mobile no.";
        }
        if (countryId.value === 1 && !state.pan) {
            isValid = false;
            errs[".pan"] = "Please enter PAN";
        }
        if (countryId.value === 1 && !state.adharcardno) {
            isValid = false;
            errs[".adharcardno"] = "Enter Adhar Card No.";
        }
        if (countryId.value !== 1 && !state.passportno) {
            isValid = false;
            errs[".passportno"] = "Enter passport no.";
        }
        if (!selfRealizationYear) {
            isValid = false;
            errs[".selfRealizationYear"] = "Select self realization year";
        }
        if (!state.center) {
            isValid = false;
            errs[".center"] = "Enter center";
        }
        if (!state.coordinatorname) {
            isValid = false;
            errs[".coordinatorname"] = "Enter center coordinator name";
        }
        if (!state.coordinatorcontact) {
            isValid = false;
            errs[".coordinatorcontact"] = "Enter center coordinator contact";
        }
        if (!arrivalTime) {
            isValid = false;
            errs[".arrivalTime"] = "Select arrival time";
        }
        if (!departureTime) {
            isValid = false;
            errs[".departureTime"] = "Select departure time";
        }
        if (otherVisiters.length > 0) {
            otherVisiters.forEach(element => {
                if (!element.firstname) {
                    isValid = false;
                    errs[".elefirstname"] = "Enter first name";
                }
                if (!element.lastname) {
                    isValid = false;
                    errs[".elelastname"] = "Enter last name";
                }
                if (!element.emailid) {
                    isValid = false;
                    errs[".eleemailid"] = "Enter email id";
                }
                if (!element.mobileno) {
                    isValid = false;
                    errs[".elemobileno"] = "Enter mobile no";
                }
                if (countryId.value === 1 && !element.adharcardno) {
                    isValid = false;
                    errs[".eleadharcardno"] = "Enter adhar card no";
                }
                if (countryId.value === 1 && !element.pan) {
                    isValid = false;
                    errs[".elepan"] = "Enter pan";
                }
                if (countryId.value !== 1 && !element.passportno) {
                    isValid = false;
                    errs[".elepassportno"] = "Enter passport no";
                }
                if (!element.visitertype) {
                    isValid = false;
                    errs[".elevisitertype"] = "Select type";
                }
            });
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const visiterTypeChangeHandler = (ev) => {
        setVisiterType(ev);
    }
    const selfRealizationYearChangeHandler = (ev) => {
        setSelfRealizationYear(ev);
    }

    const arrivalTimeChangeHandler = (ev) => {
        setArrivalTime(ev);
    }
    const departureTimeChangeHandler = (ev) => {
        setDepartureTime(ev);
    }

    function AddOtherVisiters(groupLeaderid, distid) {
        let flag = true;
        setLoading(true);
        otherVisiters.forEach(element => {
            fetch(
                service.ADD_UPDATE_VISITER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: 0,
                        FirstName: element.firstname,
                        MiddleName: element.middlename,
                        LastName: element.lastname,
                        Address: '',
                        CountryId: countryId.value,
                        StateId: stateId.value,
                        DistrictId: distid,
                        Center: state.center,
                        MobileNo: element.mobileno,
                        EmailId: element.emailid,
                        Gender: element.gender.value,
                        PAN: element.pan,
                        CenterCoordinatorContactNo: state.coordinatorcontact,
                        CenterCoordinatorName: state.coordinatorname,
                        AdharCardNo: element.adharcardno,
                        PassportNo: element.passportno,
                        BirthDate: moment(element.birthdate).format("MM/DD/YYYY"),
                        ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
                        DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
                        ArrivalTime: arrivalTime.value,
                        DepartureTime: departureTime.value,
                        SelfRealizationYear: '0',
                        VisiterType: element.visitertype.value,
                        Status: 'Pending',
                        GroupLeaderId: groupLeaderid,
                        AddedBy: groupLeaderid,
                        UpdatedBy: 0,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Failed') {
                        flag = false;
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        });
        if (flag) {
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            navigate('/visiters');
        }
        setLoading(false);
    }

    const AddMainVisiter = e => {
        e.preventDefault();
        if (validate()) {
            fetch(
                service.ADD_UPDATE_VISITER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FirstName: state.firstname,
                        MiddleName: state.middlename,
                        LastName: state.lastname,
                        Address: state.address,
                        CountryId: countryId.value,
                        StateId: stateId.value,
                        DistrictId: districtId.value,
                        Center: state.center,
                        MobileNo: state.mobileno,
                        EmailId: state.emailid,
                        Gender: gender.value,
                        PAN: state.pan,
                        CenterCoordinatorContactNo: state.coordinatorcontact,
                        CenterCoordinatorName: state.coordinatorname,
                        AdharCardNo: state.adharcardno,
                        PassportNo: state.passportno,
                        BirthDate: moment(birthDate).format("MM/DD/YYYY"),
                        ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
                        DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
                        ArrivalTime: arrivalTime.value,
                        DepartureTime: departureTime.value,
                        SelfRealizationYear: selfRealizationYear.value,
                        VisiterType: visiterType.value,
                        Status: 'Pending',
                        GroupLeaderId: 0,
                        AddedBy: 0,
                        UpdatedBy: 0,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        AddOtherVisiters(result.visiterId, districtId.value);
                    }
                    else if (result.message.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
    }

    const handleFormChange = (index, event, type) => {
        const data = [...otherVisiters];
        if (type === 'birthdate') {
            data[index].birthdate = new Date(event);
        }
        else if (type === 'gender') {
            data[index].gender = event;
        }
        else if (type === 'visitertype') {
            data[index].visitertype = event;
        }
        else {
            data[index][event.target.name] = event.target.value;
        }
        setOtherVisiters(data);
    }

    const addFields = () => {
        const newfield = {
            firstname: '', middlename: '', lastname: '', emailid: '', mobileno: '', passportno: '', pan: '', adharcardno: '', birthdate: new Date(),
            gender: '', visitertype: ''
        }
        setOtherVisiters([...otherVisiters, newfield])
    }

    const removeFields = (index) => {
        const data = [...otherVisiters];
        data.splice(index, 1)
        setOtherVisiters(data)
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ width: '30%' }}>
                                                    <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                        name="firstname"
                                                        onChange={changeHandler}
                                                        id="firstname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '30%' }}>
                                                    <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                        name="middlename"
                                                        onChange={changeHandler}
                                                        id="middlename"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                        name="lastname"
                                                        onChange={changeHandler}
                                                        id="lastname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Address" fullWidth value={state.address || ''}
                                                        name="address"
                                                        onChange={changeHandler}
                                                        id="address"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={countryData}
                                                        name="country"
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        placeholder="Select Country"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />                                                    
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        placeholder="Select State"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        type="email"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="gender"
                                                        name="gender"
                                                        options={genderData}
                                                        value={gender}
                                                        onChange={ev => setGender(ev)}
                                                        placeholder="Select Gender"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                        name="pan"
                                                        onChange={changeHandler}
                                                        id="pan"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Adhar Card No" fullWidth value={state.adharcardno || ''}
                                                        name="adharcardno"
                                                        onChange={changeHandler}
                                                        id="adharcardno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharcardno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Passport No" fullWidth value={state.passportno || ''}
                                                        name="passportno"
                                                        onChange={changeHandler}
                                                        id="passportno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".passportno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="visiterType"
                                                        name="visiterType"
                                                        options={visterTypeData}
                                                        value={visiterType}
                                                        onChange={visiterTypeChangeHandler}
                                                        placeholder="Type"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".visiterType"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={yearList}
                                                        name="selfRealizationYear"
                                                        value={selfRealizationYear}
                                                        onChange={selfRealizationYearChangeHandler}
                                                        placeholder="Select Self Realization Year"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".selfRealizationYear"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    Birth Date
                                                    <DatePicker
                                                        name="birthDate"
                                                        selected={birthDate}
                                                        onChange={date => setBirthDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Center" fullWidth value={state.center || ''}
                                                        name="center"
                                                        onChange={changeHandler}
                                                        id="center"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Center Coordinator Name" fullWidth value={state.coordinatorname || ''}
                                                        name="coordinatorname"
                                                        onChange={changeHandler}
                                                        id="coordinatorname"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coordinatorname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Center Coordinator Contact" fullWidth value={state.coordinatorcontact || ''}
                                                        name="coordinatorcontact"
                                                        onChange={changeHandler}
                                                        id="coordinatorcontact"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coordinatorcontact"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Arrival Date
                                                    <DatePicker
                                                        name="arrivalDate"
                                                        selected={arrivalDate}
                                                        onChange={date => setArrivalDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    Arrival Time
                                                    <Select
                                                        options={timeData}
                                                        name="arrivalTime"
                                                        value={arrivalTime}
                                                        onChange={arrivalTimeChangeHandler}
                                                        placeholder="Select Time"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".arrivalTime"]}</div>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Departure Date
                                                    <DatePicker
                                                        name="departureDate"
                                                        selected={departureDate}
                                                        onChange={date => setDepartureDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    Departure Time
                                                    <Select
                                                        options={timeData}
                                                        name="departureTime"
                                                        value={departureTime}
                                                        onChange={departureTimeChangeHandler}
                                                        placeholder="Select Time"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".departureTime"]}</div>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="3" >
                                                    <hr />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="3" align="center" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Member Details
                                                    <Table>
                                                        {otherVisiters.map((input, index) => (
                                                            <TableRow
                                                                key={input.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>
                                                                    <Table>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: '12px', width: '30%' }}>
                                                                                <MDInput label="First Name"
                                                                                    fullWidth value={input.firstname}
                                                                                    name="firstname"
                                                                                    onChange={event => handleFormChange(index, event, 'firstname')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elefirstname"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px', width: '30%' }}>
                                                                                <MDInput label="Middle Name"
                                                                                    fullWidth value={input.middlename}
                                                                                    name="middlename"
                                                                                    onChange={event => handleFormChange(index, event, 'middlename')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elemiddlename"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px', width: '30%' }}>
                                                                                <MDInput label="Last Name"
                                                                                    fullWidth value={input.lastname}
                                                                                    name="lastname"
                                                                                    onChange={event => handleFormChange(index, event, 'lastname')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elelastname"]}</div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <Dropdown menuClassName='myMenuClassName' id="gender"
                                                                                    name="gender"
                                                                                    options={genderData}
                                                                                    value={input.gender}
                                                                                    onChange={event => handleFormChange(index, event, 'gender')}
                                                                                    placeholder="Select Gender"
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elegender"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <MDInput label="Email Id"
                                                                                    fullWidth value={input.emailid}
                                                                                    name="emailid"
                                                                                    onChange={event => handleFormChange(index, event, 'emailid')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".eleemailid"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <MDInput label="Mobile No"
                                                                                    fullWidth value={input.mobileno}
                                                                                    name="mobileno"
                                                                                    onChange={event => handleFormChange(index, event, 'mobileno')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elemobileno"]}</div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <MDInput label="PAN"
                                                                                    fullWidth value={input.pan}
                                                                                    name="pan"
                                                                                    onChange={event => handleFormChange(index, event, 'pan')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elepan"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <MDInput label="Adhar Card No"
                                                                                    fullWidth value={input.adharcardno}
                                                                                    name="adharcardno"
                                                                                    onChange={event => handleFormChange(index, event, 'adharcardno')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".eleadharcardno"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <MDInput label="Passport"
                                                                                    fullWidth value={input.passportno}
                                                                                    name="passportno"
                                                                                    onChange={event => handleFormChange(index, event, 'passportno')}
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elepassportno"]}</div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                Birth Date
                                                                                <DatePicker
                                                                                    name="birthdate"
                                                                                    selected={input.birthdate}
                                                                                    onChange={date => handleFormChange(index, date, 'birthdate')}
                                                                                    dateFormat="MM/dd/yyyy"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <Dropdown menuClassName='myMenuClassName' id="visiterType"
                                                                                    name="visiterType"
                                                                                    options={visterTypeData}
                                                                                    value={input.visitertype}
                                                                                    onChange={event => handleFormChange(index, event, 'visitertype')}
                                                                                    placeholder="Type"
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elevisitertype"]}</div>
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                                <MDButton color="secondary" onClick={() => removeFields(index)}>
                                                                                    Remove
                                                                                </MDButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="3" >
                                                                                <hr />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow>
                                                            <TableCell style={{ fontSize: '12px' }}>
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".othervisiter"]}</div>
                                                                <MDButton color="secondary" onClick={addFields}>
                                                                    Add Member
                                                                </MDButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={AddMainVisiter}>
                                            Save
                                        </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
export default AddVisiter;