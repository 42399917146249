/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import MDButton from "components/MDButton";
// @mui icons
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function UpdateStatus() {
    const [visiterData, setVisiterData] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const getVisitersId = () => {
        setLoading(true);
        // const errs = {};      
        if (params.id > 0) {
            fetch(
                service.GET_VISITER_GROUP_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setVisiterData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result[0].message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getVisitersId();
    }, []);

    // const printDocument = () => {
    //     const input = document.getElementById('tblReceipt');
    //     html2canvas(input, { scale: "5" })
    //         .then((canvas) => {
    //             // const imgData = canvas.toDataURL('image/png');
    //             const imgData = canvas.toDataURL('image/jpeg', 0.3);
    //             const imgWidth = 190;
    //             const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //             // eslint-disable-next-line new-cap
    //             const doc = new jsPDF('pt', 'mm', 'a4', true);
    //             // doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
    //             doc.addImage(imgData, "JPEG", 5, 0, imgWidth, imgHeight + 25, undefined,'FAST');
    //             doc.save('Receipt.pdf');
    //         });
    // }
    const submitHandler = () => {
        navigate('/visiters');
    };

    function SendMail(status) {
        setLoading(true);
        fetch(
          service.SEND_STATUS_MAIL,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              VisiterId: visiterData[0].visiterId,
              Status: status,
            })
          })
          .then(res => res.json())
          .then(result => {
            if (result.messageType === 'Failed') {          
              // eslint-disable-next-line
              alert(result.message);
            }
          });
        setLoading(false);
      }

    const updateHandler = (e, status) => {
        e.preventDefault();
        setLoading(true);
        let flag = true;
        visiterData.forEach(element => {
            fetch(
                service.UPDATE_VISITER_STATUS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: element.visiterId,
                        Status: status,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Failed') {
                        flag = false;
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        });

        if (flag) {
            SendMail(status);
            // eslint-disable-next-line
            alert("Status Saved Successfully");
            window.location.reload(false);
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Visiter Details
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                <Card style={{ alignItems: 'center' }}>
                                    {visiterData.length > 0 ?
                                        <div style={{ width: '100%', alignSelf: 'center' }}>
                                            <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Country :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].country}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                State :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].state}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                District :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].district}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Address :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].address}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Center :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].center}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Center Coordinator :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].centerCoordinatorName}, {visiterData[0].centerCoordinatorContactNo}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Arrival Date-Time :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {moment(visiterData[0].arrivalDate).format('DD-MM-YYYY')} - {visiterData[0].arrivalTime}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Departure Date-Time :
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {moment(visiterData[0].departureDate).format('DD-MM-YYYY')} - {visiterData[0].departureTime}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Status :
                                                            </TableCell>
                                                            <TableCell colSpan="7" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {visiterData[0].status}
                                                            </TableCell>
                                                        </TableRow>
                                                        <br />
                                                        <TableRow>
                                                            <TableCell colSpan="8">
                                                                <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Sr.No.
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Name
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            PAN
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Type
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Adhar Card No
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Mobile No
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Passport No
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Gender
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            EmailId
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Birth Date
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {visiterData.map((row, index) => (
                                                                        <TableRow key={row.visiterId}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {index + 1}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.firstName} {row.middleName} {row.lastName}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.pan}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.visiterType}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.adharCardNo}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.mobileNo}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.passportNo}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.gender}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {row.emailId}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {moment(row.birthDate).format('DD-MM-YYYY')}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <MDBox mt={4} mb={1} mx={30} width="60%" textAlign="center">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>
                                                            <MDButton color="info" disabled={visiterData[0].status === 'Approved' ||
                                                                visiterData[0].status === 'Visited' ||
                                                                visiterData[0].status === 'Cancelled'}
                                                                onClick={e => updateHandler(e, 'Approved')}>
                                                                Approve
                                                            </MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton color="info" disabled={visiterData[0].status === 'Rejected' ||
                                                                visiterData[0].status === 'Visited' ||
                                                                visiterData[0].status === 'Cancelled'}
                                                                onClick={e => updateHandler(e, 'Rejected')}>
                                                                Reject
                                                            </MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton color="info" disabled={visiterData[0].status === 'Visited' ||
                                                                visiterData[0].status === 'Cancelled' ||
                                                                visiterData[0].status === 'Pending' ||
                                                                visiterData[0].status === 'Rejected'}
                                                                onClick={e => updateHandler(e, 'Visited')}>
                                                                Visited
                                                            </MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton color="info" disabled={visiterData[0].status === 'Cancelled' ||
                                                                                            visiterData[0].status === 'Visited'}
                                                                onClick={e => updateHandler(e, 'Cancelled')}>
                                                                Cancelled
                                                            </MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton color="secondary" onClick={submitHandler}>
                                                                Back
                                                            </MDButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </MDBox>
                                        </div>
                                        : null}

                                </Card>
                            </MDBox>
                        </MDBox>
                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default UpdateStatus;
