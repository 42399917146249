
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../../services/service';

function AddReceipt() {
    const [state, setState] = useState(
        {
            donation: ''
        });
    // const [bankId, setBankId] = useState('');
    // const [bankData, setBankData] = useState([]);
    const [visiterData, setVisiterData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentDate, setPaymentDate] = useState(new Date());
    const currencyData = [{ label: "INR", value: "INR" }];
    const paymentModeData = [{ label: "Cash", value: "Cash" }, { label: "QR Code", value: "QR Code" }, { label: "Card", value: "Card" }];
    const [adultContri, setAdultContri] = useState('');
    const [yuvaContri, setYuvaContri] = useState('');
    const [balContri, setBalContri] = useState('');
    const [days, setDays] = useState('');
    const [amount, setAmount] = useState('');
    const [visiterId, setVisiterId] = useState('');
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    const navigate = useNavigate();
    const params = useParams();

    const getVisitersPayment = (id) => {
        setLoading(true);
        // const errs = {};      
        if (params.id > 0) {
            fetch(
                service.GET_VISITER_PAYMENT,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setPaymentData(result);
                });
        }
        setLoading(false);
    };

    const getVisitersId = (aduCon, yuCon, blCon) => {
        setLoading(true);
        // const errs = {};      
        if (params.id > 0) {
            fetch(
                service.GET_VISITER_GROUP_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setVisiterData(result);
                        const date1 = new Date(result[0].arrivalDate);
                        const date2 = new Date(result[0].departureDate);
                        // To calculate the time difference of two dates
                        const timeDiff = date2.getTime() - date1.getTime();

                        // To calculate the no. of days between two dates
                        const dayDiff = (timeDiff / (1000 * 3600 * 24)) + 1;
                        setDays(dayDiff);
                        let amt = 0;
                        result.forEach(element => {
                            if (element.visiterType === 'Adult') {
                                amt += (aduCon * dayDiff);
                            }
                            else if (element.visiterType === 'Yuvashakti') {
                                amt += (yuCon * dayDiff);
                            }
                            else if (element.visiterType === 'Balshakti') {
                                amt += (blCon * dayDiff);
                            }
                        });
                        setAmount(amt);

                        const id = result.filter(e => e.groupLeaderId === 0);
                        setVisiterId(id[0].visiterId);

                        getVisitersPayment(id[0].visiterId);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result[0].message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDonationTypes = () => {
        fetch(
            service.VISIT_CHARGES_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                // const data2 = result.map((item) => ({ value: item.donationTypeId, label: item.name }));
                // setDonationTypeData(data2);
                // assuming single database per puja
                setAdultContri(result[0].adultContribution);
                setYuvaContri(result[0].yuvaContribution);
                setBalContri(result[0].balContribution);

                getVisitersId(result[0].adultContribution, result[0].yuvaContribution, result[0].balContribution)
            });
    };

    // const getBanks = () => {
    //     fetch(
    //         service.BANK_URL,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             const data2 = result.map((item) => ({ value: item.bankId, label: item.bank }));
    //             setBankData(data2);
    //         });
    // };

    useEffect(() => {
        getDonationTypes();
        // getVisitersId();
        const cur = { label: 'INR', value: 'INR' };
        setCurrency(cur);

        const mode = { label: 'Cash', value: 'Cash' };
        setPaymentMode(mode);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.donation) {
            isValid = false;
            errs[".donation"] = "Please enter donation";
        }
        // if (paymentMode.value === "Bank Transfer" && !bankId) {
        //     isValid = false;
        //     errs[".bank"] = "Please select bank";
        // }
        // if (paymentMode.value === "Bank Transfer" && !state.utrCode) {
        //     isValid = false;
        //     errs[".utrCode"] = "Please enter UTRCode";
        // }       
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            setState({ ...state, [event.target.name]: event.target.value });
        }
    };

    const paymentModeChangeHandler = (ev) => {
        setPaymentMode(ev);
    }
    const backHandler = () => {
        navigate('/visiters');
    };
    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_VISITER_PAYMENT,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: visiterId,
                        Contribution: state.donation,
                        Currency: currency.value,
                        PaymentMode: paymentMode.value,
                        PaymentDate: moment(paymentDate).format("MM/DD/YYYY"),
                        // UTRCode: state.utrCode,
                        // BankId: bankId.value,
                        // RazorOrderId: razorOrderId,
                        // RazorPaymentId: razorPaymentId,
                        // RazorSignature: razorSignature,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(resultP => {
                    if (resultP.message.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(resultP.message.message);
                        navigate('/visiters');
                    }
                    else if (resultP.message.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(resultP.message.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(resultP.message.message);
                        // errs[".invalid"] = resultP.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Visiter Details
                                    </MDTypography>
                                </MDBox>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <MDBox pt={4} pb={3} px={3}>
                                        <MDBox component="form" role="form">
                                            <Card style={{ alignItems: 'center' }}>
                                                {visiterData.length > 0 ?
                                                    <div style={{ width: '100%', alignSelf: 'center' }}>
                                                        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                            <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Country :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].country}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            State :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].state}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            District :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].district}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Address :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].address}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Center :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].center}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Center Coordinator :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].centerCoordinatorName}, {visiterData[0].centerCoordinatorContactNo}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Arrival Date-Time :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(visiterData[0].arrivalDate).format('DD-MM-YYYY')} - {visiterData[0].arrivalTime}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Departure Date-Time :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(visiterData[0].departureDate).format('DD-MM-YYYY')} - {visiterData[0].departureTime}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Status :
                                                                        </TableCell>
                                                                        <TableCell colSpan="7" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {visiterData[0].status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <br />
                                                                    <TableRow>
                                                                        <TableCell colSpan="8">
                                                                            <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Sr.No.
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Name
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        PAN
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Type
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Adhar Card No
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Mobile No
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Passport No
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Gender
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        EmailId
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Birth Date
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                {visiterData.map((row, index) => (
                                                                                    <TableRow key={row.visiterId}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.firstName} {row.middleName} {row.lastName}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.pan}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.visiterType}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.adharCardNo}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.mobileNo}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.passportNo}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.gender}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {row.emailId}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                            {moment(row.birthDate).format('DD-MM-YYYY')}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </Table>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                    : null}
                                            </Card>
                                        </MDBox>
                                    </MDBox>
                                }
                                <Card style={{ alignItems: 'center' }}>
                                    <br />
                                    <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Donation Details</div>
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <div style={{ fontSize: '13px', color: 'green', fontWeight: 'bold' }}>Adult Contribution : {adultContri}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Yuva Contribution : {yuvaContri}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Balshakti Contribution : {balContri}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '13px', fontWeight: 'bold' }}>Total Days: {days}</TableCell>
                                                    <TableCell style={{ fontSize: '13px', fontWeight: 'bold' }}>Expected Donation: {amount}</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <MDInput label="Donation" fullWidth value={state.donation}
                                                            name="donation"
                                                            onChange={changeHandler}
                                                            id="donation"
                                                            required
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".donation"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="currency"
                                                            name="currency"
                                                            options={currencyData}
                                                            value={currency}
                                                            onChange={ev => setCurrency(ev)}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".currency"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Dropdown menuClassName='myMenuClassName' id="paymentMode"
                                                            name="paymentMode"
                                                            options={paymentModeData}
                                                            value={paymentMode}
                                                            onChange={paymentModeChangeHandler}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentMode"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        Donation Date
                                                        <DatePicker
                                                            id="paymentDate"
                                                            selected={paymentDate}
                                                            onChange={date => setPaymentDate(date)}
                                                            dateFormat="MM/dd/yyyy"
                                                            tabIndex={0}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentDate"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Table style={{ width: '20%' }}>
                                        <TableRow>
                                            <TableCell>
                                                <MDBox mt={4} mb={1} textAlign="center">
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <div>
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                SAVE
                                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <MDButton color="secondary" onClick={backHandler}>
                                                                Back
                                                            </MDButton>
                                                        </div>
                                                    }
                                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    <Table style={{ width: '50%', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        <TableRow>
                                            <TableCell colSpan="3" align='center' style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                Donations Given
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                Donation
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                Donation Mode
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                Donation Date
                                            </TableCell>
                                        </TableRow>
                                        {paymentData.map((row) => (
                                            <TableRow key={row.paymentId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    {row.contribution}
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    {row.paymentMode}
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    {moment(row.paymentDate).format('DD-MM-YYYY')}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </Card>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Footer />
        </DashboardLayout>
    );
}

export default AddReceipt;