// const baseURL = `https://localhost:44379/api/`;
 const baseURL = `https://pratishthanvisitapi.sahajayogaindia.org.in/api/`;

export const LOGIN_URL = `${baseURL}Account/login`;
export const BANK_URL = `${baseURL}Masters/getbanks`;
export const GET_BANK_BY_ID = `${baseURL}Masters/getbankbyid`;
export const ADD_UPDATE_BANK = `${baseURL}Masters/addeditbank`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_COUNTRY_BY_ID = `${baseURL}Masters/getcountrybyid`;
export const ADD_UPDATE_COUNTRY = `${baseURL}Masters/addeditcountry`;
export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_STATE_BY_ID = `${baseURL}Masters/getstatebyid`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const ADD_UPDATE_STATE = `${baseURL}Masters/addeditstate`;
export const DISTRICT_URL = `${baseURL}Masters/getdistricts`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_UPDATE_DISTRICT = `${baseURL}Masters/addeditdistrict`;
export const VISIT_CHARGES_URL = `${baseURL}Masters/getvisitcharges`;
export const GET_DONATION_TYPES_BY_ID = `${baseURL}Masters/getdonationtypebyid`;
export const ADD_UPDATE_DONATION_TYPE = `${baseURL}Masters/addeditdonationtype`;
export const USERS_URL = `${baseURL}Masters/getusers`;
export const GET_ACTIVE_USERS = `${baseURL}Masters/getactiveusers`;
export const GET_USER_BY_ID = `${baseURL}Masters/getuserbyid`;
export const ADD_UPDATE_USER = `${baseURL}Masters/addedituser`;
export const UPDATE_USER_STATUS = `${baseURL}Masters/updateuserstatus`;
export const ROOM_URL = `${baseURL}Masters/getrooms`;
export const GET_ROOM_BY_ID = `${baseURL}Masters/getroombyid`;
export const ADD_UPDATE_ROOM = `${baseURL}Masters/addeditroom`;
// export const GET_ROOM_AVAILABLE_CAPACITY = `${baseURL}Masters/getroomavailablecapacity`;

export const VISITERS_BY_SEARCH_URL = `${baseURL}Visiters/getvisitersbysearch`;
export const GET_VISITER_BY_ID = `${baseURL}Visiters/getvisiterbyid`;
export const GET_VISITER_GROUP_BY_ID = `${baseURL}Visiters/getvisitersgroupbyid`;
export const ADD_UPDATE_VISITER = `${baseURL}Visiters/addupdatevisiter`;
export const ADD_VISITER_PAYMENT = `${baseURL}Visiters/addvisiterpayment`;
export const UPDATE_VISITER_STATUS = `${baseURL}Visiters/updatevisiterstatus`;
export const UPDATE_VISITER_ROOM = `${baseURL}Visiters/updatevisiterroom`;
export const GET_VISITER_PAYMENT = `${baseURL}Visiters/getvisiterpayments`;
export const VISITERS_BY_SEARCH_FOR_RECEIPT = `${baseURL}Visiters/updatevisiterstatus`;

export const GET_TOTAL_COUNT = `${baseURL}Visiters/gettotalcounts`;
export const GET_TOTAL_VISITED_COUNT = `${baseURL}Visiters/gettotalvisitedcounts`;
export const GET_TODAYS_COUNT = `${baseURL}Visiters/gettodayscount`;
export const GET_STATE_WISE_COUNT = `${baseURL}Visiters/getstatewisecounts`;
export const GET_MAHARASHTRA_DISTRICT_WISE_COUNT = `${baseURL}Visiters/getmaharashtradistrictwisecounts`;
export const GET_VISITER_DETAILS_REPORT = `${baseURL}Visiters/getvisiterdetailsreport`;
export const GET_APPROVED_VISITER = `${baseURL}Visiters/getapprovedvisiters`;
export const GET_MONTH_WISE_SUMMARY_REPORT = `${baseURL}Visiters/getmonthwisesummaryreport`;

export const GET_PAYMENT_BY_ID = `${baseURL}Visiters/getpaymentdetailsbyid`;
export const SEND_STATUS_MAIL = `${baseURL}Visiters/sendstatusmail`;

export default LOGIN_URL;