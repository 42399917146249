import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import * as moment from 'moment';
import * as service from '../../services/service';

function Visiter() {
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [status, setStatus] = useState('');
    // const [error, setError] = useState(null);
    const [dataError, setDataError] = useState(null);
    const [loading, setLoading] = useState(false);
    const statusData = [{ label: "Pending", value: "Pending" }, { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" }, { label: "Visited", value: "Visited" }, { label: "Cancelled", value: "Cancelled" }];

    const [action, setAction] = useState('');
    const actionData = [{ label: "Edit", value: "Edit" }, { label: "Update Status", value: "Update Status" },
    { label: "Allocate Room", value: "Allocate Room" }, { label: "Donation", value: "Donation" }];

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    const navigate = useNavigate();

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getDetails = () => {
        setLoading(true);
        fetch(
            service.VISITERS_BY_SEARCH_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Search: search,
                    StateId: stateId.value,
                    DistrictId: districtId.value,
                    Status: status.value ? status.value : 'Pending'
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    setDataError(result[0].message.message);
                    setData([]);
                }
                else {
                    setData(result);
                    setDataError('');
                }
            })
            .catch(err => {
                if (err) {
                    setDataError(err);
                };
            });

        setLoading(false);
    };

    // const handleEdit = (id) => {
    //     navigate(`/editvisiter/${id}`);
    // }
    // const handleDonation = (id) => {
    //     navigate(`/addreceipt/${id}`);
    // }
    // const handleUpdateStatus = (id) => {
    //     navigate(`/updatestatus/${id}`);
    // }
    // const handleRoom = (id) => {
    //     navigate(`/allocateroom/${id}`);
    // }
    useEffect(() => {
        setLoading(true);
        getCountries();
        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);

        const st = { value: 'Pending', label: 'Pending' };
        setStatus(st);
        getDetails();
        setLoading(false);
    }, []);
    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const statusChangeHandler = (ev) => {
        setStatus(ev);
    }

    const actionChangeHandler = (ev, id) => {
        setAction(ev);
        if (ev.value === 'Edit') {
            navigate(`/editvisiter/${id}`);
        }
        else if (ev.value === 'Update Status') {
            navigate(`/updatestatus/${id}`);
        }
        else if (ev.value === 'Allocate Room') {
            navigate(`/allocateroom/${id}`);
        }
        else if (ev.value === 'Donation') {
            navigate(`/addreceipt/${id}`);
        }
    }
    const addNew = () => {
        navigate('/addvisiter');
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <div>
                    <div style={{ float: 'right' }}>
                        <MDButton color="info" onClick={addNew}>
                            ADD NEW
                        </MDButton>
                    </div>
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Visiters
                                        </MDTypography>
                                    </MDBox>
                                    <br />
                                    <Table>
                                        <TableCell width="15%">
                                            <MDInput label="search" value={search}
                                                name="search"
                                                onChange={ev => setSearch(ev.target.value)}
                                                id="search"
                                                required
                                            />
                                            <br />
                                            <div style={{ fontSize: '12px', color: 'green' }}>Search by FirstName, LastName, MobileNo, Adhar Card No, District, or Receipt No</div>
                                        </TableCell>
                                        <TableCell width="15%">
                                            <Select
                                                options={countryData}
                                                name="country"
                                                value={countryId}
                                                onChange={countryChangeHandler}
                                                placeholder="Select Country"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell width="15%">
                                            <Select
                                                options={stateData}
                                                name="state"
                                                value={stateId}
                                                onChange={stateChangeHandler}
                                                placeholder="Select State"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell width="20%">
                                            <Select className="basic-single"
                                                options={districtData}
                                                name="district"
                                                value={districtId}
                                                onChange={districtChangeHandler}
                                                isSearchable
                                                placeholder="Select District"
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell width="15%">
                                            <Select className="basic-single"
                                                options={statusData}
                                                name="status"
                                                value={status}
                                                onChange={statusChangeHandler}
                                                isSearchable
                                                placeholder="Select Status"
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MDButton color="info" onClick={getDetails} endIcon={<SearchIcon />}>
                                                Search
                                            </MDButton>
                                        </TableCell>
                                    </Table>

                                    {/* <div style={{ fontSize: '12px', color: 'red' }}>{error}</div> */}
                                    <br />
                                    <Card style={{ alignItems: 'center' }}>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{dataError}</div>
                                        {loading ? <Oval
                                            heigth="100"
                                            width="100"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            // <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                <Table aria-label="simple table">
                                                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Name</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Gender</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Type</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>MobileNo</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Arrival</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Departure</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Group Leader</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Room</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                                                        <TableCell align='center' style={{ fontWeight: 'bold', fontSize: '14px' }}>Actions</TableCell>
                                                    </TableRow>

                                                    <TableBody>
                                                        {data.map((row) => (
                                                            <TableRow
                                                                key={row.visiterId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell width='25%' style={{ fontSize: '14px' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.district}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.gender}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.visiterType}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.mobileNo}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{moment(row.arrivalDate).format("DD/MM/YYYY")} - {row.arrivalTime}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{moment(row.departureDate).format("DD/MM/YYYY")} - {row.departureTime}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.groupLeaderName}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.roomName}</TableCell>
                                                                <TableCell width='5%' style={{ fontSize: '14px' }}>{row.status}</TableCell>
                                                                <TableCell width='30%'>
                                                                    <Select
                                                                        options={actionData}
                                                                        name="action"
                                                                        value={action}
                                                                        onChange={ev => actionChangeHandler(ev, row.visiterId)}
                                                                        placeholder="Action"
                                                                        isSearchable
                                                                        styles={selectStyles}
                                                                    />                                                                    
                                                                </TableCell>
                                                                {/* <TableCell width='2%' style={{ fontSize: '14px' }}><MDButton onClick={() => handleEdit(row.visiterId)}>Edit</MDButton></TableCell>
                                                                <TableCell width='2%' style={{ fontSize: '14px' }}><MDButton onClick={() => handleUpdateStatus(row.visiterId)}>Update Status</MDButton></TableCell>
                                                                <TableCell width='2%' style={{ fontSize: '14px' }}><MDButton onClick={() => handleRoom(row.visiterId)}>Allocate Room</MDButton></TableCell>
                                                                <TableCell width='2%' style={{ fontSize: '14px' }}><MDButton onClick={() => handleDonation(row.visiterId)}>Donation</MDButton></TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            // </TableContainer>
                                        }
                                    </Card>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox >
                </div>
            }
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout >
    );
}
export default Visiter;