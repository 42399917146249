
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as service from '../../../../services/service';

function AddEditVisitCharges() {
    const [state, setState] = useState({ adultcontri: '', yuvacontri: '', balcontri: '' });
    const [currency, setCurrency] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const currencyData = [{ label: "INR", value: "INR" }];

    const navigate = useNavigate();
    const params = useParams();

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_DONATION_TYPES_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DetailId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, adultcontri: result.adultContribution,
                            yuvacontri: result.yuvaContribution, balcontri: result.balContribution
                        });

                        const cur = ({ value: result.currency, label: result.currency });
                        setCurrency(cur);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getDetailsById();
        }
        else {
            const cur = { label: 'INR', value: 'INR' };
            setCurrency(cur);
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.adultcontri) {
            isValid = false;
            errs[".adultcontri"] = "Please enter adult contribution";
        }
        if (!state.yuvacontri) {
            isValid = false;
            errs[".yuvacontri"] = "Please enter yuva contribution";
        }
        if (!state.balcontri) {
            isValid = false;
            errs[".balcontri"] = "Please enter bal contribution";
        }

        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_DONATION_TYPE,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DetailId: params.id ? params.id : null,
                        AdultContribution: state.adultcontri,
                        YuvaContribution: state.yuvacontri,
                        BalContribution: state.balcontri,
                        Currency: currency.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/visitcharges');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/visitcharges');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', height: '400px', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>                                          
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Adult Contribution" fullWidth value={state.adultcontri || ''}
                                                        name="adultcontri"
                                                        onChange={changeHandler}
                                                        id="adultcontri"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adultcontri"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Yuva Contribution" fullWidth value={state.yuvacontri || ''}
                                                        name="yuvacontri"
                                                        onChange={changeHandler}
                                                        id="yuvacontri"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".yuvacontri"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Bal Contribution" fullWidth value={state.balcontri || ''}
                                                        name="balcontri"
                                                        onChange={changeHandler}
                                                        id="balcontri"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".balcontri"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="currency"
                                                        name="currency"
                                                        options={currencyData}
                                                        value={currency}
                                                        onChange={ev => setCurrency(ev)}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".currency"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler}>
                                            SAVE
                                        </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditVisitCharges;