
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import Select from "react-select";
import * as service from '../../../services/service';

function EditVisiter() {
    const [state, setState] = useState({ firstname: '', middlename: '', lastname: '', center: '', pan: '', adharcardno: '', mobileno: '' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [departureDate, setDepartureDate] = useState(new Date());
    const [arrivalTime, setArrivalTime] = useState('');
    const [departureTime, setDepartureTime] = useState('');
    const timeData = [
        { label: "4 AM", value: "4 AM" }, { label: "4.30 AM", value: "4.30 AM" },
        { label: "5 AM", value: "5 AM" }, { label: "5.30 AM", value: "5.30 AM" },
        { label: "6 AM", value: "6 AM" }, { label: "6.30 AM", value: "6.30 AM" },
        { label: "7 AM", value: "7 AM" }, { label: "7.30 AM", value: "7.30 AM" },
        { label: "8 AM", value: "8 AM" }, { label: "8.30 AM", value: "8.30 AM" },
        { label: "9 AM", value: "9 AM" }, { label: "9.30 AM", value: "9.30 AM" },
        { label: "10 AM", value: "10 AM" }, { label: "10.30 AM", value: "10.30 AM" },
        { label: "11 AM", value: "11 AM" }, { label: "11.30 AM", value: "11.30 AM" },
        { label: "12 PM", value: "12 PM" }, { label: "12.30 PM", value: "12.30 PM" },
        { label: "1 PM", value: "1 PM" }, { label: "1.30 PM", value: "1.30 PM" },
        { label: "2 PM", value: "2 PM" }, { label: "2.30 PM", value: "2.30 PM" },
        { label: "3 PM", value: "3 PM" }, { label: "3.30 PM", value: "3.30 PM" },
        { label: "4 PM", value: "4 PM" }, { label: "4.30 PM", value: "4.30 PM" },
        { label: "5 PM", value: "5 PM" }, { label: "5.30 PM", value: "5.30 PM" },
        { label: "6 PM", value: "6 PM" }, { label: "6.30 PM", value: "6.30 PM" },
        { label: "7 PM", value: "7 PM" }, { label: "7.30 PM", value: "7.30 PM" },
        { label: "8 PM", value: "8 PM" }, { label: "8.30 PM", value: "8.30 PM" },
        { label: "9 PM", value: "9 PM" }, { label: "9.30 PM", value: "9.30 PM" },
        { label: "10 PM", value: "10 PM" }, { label: "10.30 PM", value: "10.30 PM" },
        { label: "11 PM", value: "11 PM" }, { label: "11.30 PM", value: "11.30 PM" },
        { label: "12 AM", value: "12 AM" }, { label: "12.30 AM", value: "12.30 AM" }];

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const navigate = useNavigate();
    const params = useParams();

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_VISITER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, firstname: result.firstName, middlename: result.middleName, lastname: result.lastName,
                            center: result.center, pan: result.pan, adharcardno: result.adharCardNo, mobileno: result.mobileNo
                        });
                        const gen = ({ value: result.gender, label: result.gender });
                        setGender(gen);
                        const arTime = ({ value: result.arrivalTime, label: result.arrivalTime });
                        setArrivalTime(arTime);
                        const depTime = ({ value: result.departureTime, label: result.departureTime });
                        setDepartureTime(depTime);

                        setArrivalDate(new Date(result.arrivalDate));
                        setDepartureDate(new Date(result.departureDate));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getDetailsById();
        }
        else {
            const gen = { label: 'Male', value: 'Male' };
            setGender(gen);
        }
    }, []);

    const arrivalTimeChangeHandler = (ev) => {
        setArrivalTime(ev);
    }
    const departureTimeChangeHandler = (ev) => {
        setDepartureTime(ev);
    }
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.firstname) {
            isValid = false;
            errs[".firstname"] = "Please enter first name";
        }
        if (!state.lastname) {
            isValid = false;
            errs[".lastname"] = "Please enter last name";
        }
        if (!state.pan) {
            isValid = false;
            errs[".pan"] = "Please enter PAN";
        }
        if (!state.adharcardno) {
            isValid = false;
            errs[".adharcardno"] = "Please enter adharcardno";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter mobileno";
        }
        if (!arrivalTime) {
            isValid = false;
            errs[".arrivalTime"] = "Select arrival time";
        }
        if (!departureTime) {
            isValid = false;
            errs[".departureTime"] = "Select departure time";
        }
        if (arrivalDate > departureDate) {
            isValid = false;
            errs[".departureDate"] = "Select proper departure date";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_VISITER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: params.id ? params.id : null,
                        FirstName: state.firstname,
                        MiddleName: state.middlename,
                        LastName: state.lastname,
                        Center: state.center,
                        Gender: gender.value,
                        PAN: state.pan,
                        AdharCardNo: state.adharcardno,
                        MobileNo: state.mobileno,
                        ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
                        DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
                        ArrivalTime: arrivalTime.value,
                        DepartureTime: departureTime.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message.message);
                        navigate('/visiters');
                    }
                    else if (result.message.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message.message);
                        navigate('/visiters');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/visiters');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', height:'600px', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Visiter Details</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                        name="firstname"
                                                        onChange={changeHandler}
                                                        id="firstname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                        name="middlename"
                                                        onChange={changeHandler}
                                                        id="middlename"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                        name="lastname"
                                                        onChange={changeHandler}
                                                        id="lastname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Center" fullWidth value={state.center || ''}
                                                        name="center"
                                                        onChange={changeHandler}
                                                        id="center"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="gender"
                                                        name="gender"
                                                        options={genderData}
                                                        value={gender}
                                                        onChange={ev => setGender(ev)}
                                                        placeholder="Select Gender"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                        name="pan"
                                                        onChange={changeHandler}
                                                        id="pan"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Adhar Card No" fullWidth value={state.adharcardno || ''}
                                                        name="adharcardno"
                                                        onChange={changeHandler}
                                                        id="adharcardno" />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharcardno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno" />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Arrival Date
                                                    <DatePicker
                                                        name="arrivalDate"
                                                        selected={arrivalDate}
                                                        onChange={date => setArrivalDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="scroll"
                                                        peekNextMonth
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'green' }}>MM/DD/YYYY</div>
                                                </TableCell>
                                                <TableCell>
                                                    Arrival Time
                                                    <Select
                                                        options={timeData}
                                                        name="arrivalTime"
                                                        value={arrivalTime}
                                                        onChange={arrivalTimeChangeHandler}
                                                        placeholder="Select Time"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".arrivalTime"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Departure Date
                                                    <DatePicker
                                                        name="departureDate"
                                                        selected={departureDate}
                                                        onChange={date => setDepartureDate(date)}
                                                        minDate={arrivalDate}
                                                        dateFormat="MM/dd/yyyy"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="scroll"
                                                        peekNextMonth
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'green' }}>MM/DD/YYYY</div>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".departureDate"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    Departure Time
                                                    <Select
                                                        options={timeData}
                                                        name="departureTime"
                                                        value={departureTime}
                                                        onChange={departureTimeChangeHandler}
                                                        placeholder="Select Time"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".departureTime"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="secondary" onClick={backHandler}>
                                                Back
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default EditVisiter;