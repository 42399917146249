
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import Select from "react-select";
import * as service from '../../../services/service';

function AllocateRoom() {
    const [visiterData, setVisiterData] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState([{}]);
    const [roomData, setRoomData] = useState([]);
    const [roomCapacityData, setRoomCapacityData] = useState([]);
    // const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const useremail = JSON.parse(sessionStorage.getItem('userData')).emailId;
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const navigate = useNavigate();
    const params = useParams();

    const getRooms = () => {
        fetch(
            service.ROOM_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {   
                setRoomCapacityData(result);             
                const data2 = result.map((item) => ({ value: item.roomId, label: item.roomName }));
                setRoomData(data2);
            });
    };

    const getVisitersId = () => {
        setLoading(true);
        // const errs = {};      
        if (params.id > 0) {
            fetch(
                service.GET_VISITER_GROUP_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        VisiterId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setVisiterData(result);
                        result.forEach((element, index) => {
                            selectedRoom[index] = { label: element.roomName, value: element.roomId };
                            setSelectedRoom([...selectedRoom]);

                        });
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result[0].message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getRooms();
        getVisitersId();        
    }, []);

    const roomChangeHandler = (event, index) => {
        let updatedObject = selectedRoom[index];
        updatedObject = event;
        selectedRoom[index] = updatedObject;
        setSelectedRoom([...selectedRoom]);
    };

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        let flag = true;
        visiterData.forEach((element, index) => {
            if (selectedRoom[index].value > 0) {
                fetch(
                    service.UPDATE_VISITER_ROOM,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            VisiterId: element.visiterId,
                            RoomId: selectedRoom[index].value,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                            UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.message.messageType === 'Failed') {
                            flag = false;
                            // eslint-disable-next-line
                            alert(result.message.message);
                        }
                    });
            }
        });


        if (flag) {
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            window.location.reload(false);
        }
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/visiters');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {useremail === 't@t.com' || useremail === 'a@a.com' ?
                <MDBox pt={6} pb={3}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', height: '500px', color: 'red' }} align='center'>
                        Access Denied
                    </div>
                </MDBox>
                :
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Visiter Details
                                    </MDTypography>
                                </MDBox>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <MDBox pt={4} pb={3} px={3}>
                                        <MDBox component="form" role="form">
                                            <Card style={{ alignItems: 'center' }}>
                                                {visiterData.length > 0 ?
                                                    <div style={{ width: '100%', alignSelf: 'center' }}>
                                                        {/* <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}> */}
                                                        <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Country :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].country}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        State :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].state}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        District :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].district}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Address :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].address}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Center :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].center}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Center Coordinator :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].centerCoordinatorName}, {visiterData[0].centerCoordinatorContactNo}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Arrival Date-Time :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {moment(visiterData[0].arrivalDate).format('DD-MM-YYYY')} - {visiterData[0].arrivalTime}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Departure Date-Time :
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {moment(visiterData[0].departureDate).format('DD-MM-YYYY')} - {visiterData[0].departureTime}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        Status :
                                                                    </TableCell>
                                                                    <TableCell colSpan="7" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {visiterData[0].status}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <br />                                                               
                                                                <TableRow>
                                                                    <TableCell colSpan="4">
                                                                        <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Room Name
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Available Capacity / Bed
                                                                                </TableCell>
                                                                            </TableRow>                                                                           
                                                                            {roomCapacityData.map(row => (                                                                                
                                                                                <TableRow key={row.roomId}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.roomName} 
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.availableCapacity}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell colSpan="8">
                                                                        <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Sr.No.
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Name
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Type
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Adhar Card No
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Mobile No
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Gender
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Birth Date
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Allocated Room
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Select Room
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {visiterData.map((row, index) => (
                                                                                <TableRow key={row.visiterId}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {index + 1}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.firstName} {row.middleName} {row.lastName}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.visiterType}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.adharCardNo}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.mobileNo}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.gender}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {moment(row.birthDate).format('DD-MM-YYYY')}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {row.roomName}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        <Select
                                                                                            options={roomData}
                                                                                            name="room"
                                                                                            value={selectedRoom[index]}
                                                                                            onChange={ev => roomChangeHandler(ev, index)}
                                                                                            placeholder="Select Room"
                                                                                            isSearchable
                                                                                            styles={selectStyles}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        {/* </TableContainer> */}
                                                        <MDBox mt={4} mb={1} textAlign="center">
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                Save
                                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <MDButton color="secondary" onClick={backHandler}>
                                                                Back
                                                            </MDButton>
                                                        </MDBox>
                                                    </div>
                                                    : null}
                                            </Card>
                                        </MDBox>
                                    </MDBox>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Footer />
        </DashboardLayout>
    );
}

export default AllocateRoom;