/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
// import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
// import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
// import booking1 from "assets/images/products/product-1-min.jpg";
// import booking2 from "assets/images/products/product-2-min.jpg";
// import booking3 from "assets/images/products/product-3-min.jpg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as service from '../../../services/service';

function Analytics() {
  const [visitedCountData, setVisitedCountData] = useState([]);
  const [todayCountData, setTodayCountData] = useState([]);
  const [countData, setCountData] = useState([]);
  const [stateCountData, setStateCountData] = useState([]);
  const [districtCountData, setDistrictCountData] = useState([]);
  const [approvedCountData, setApprovedCountData] = useState([]);

  const getTotalVisitedCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_TOTAL_VISITED_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setVisitedCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getTotalCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_TOTAL_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getTodaysCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_TODAYS_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setTodayCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getStateWiseCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_STATE_WISE_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setStateCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getMaharashtraDistrictWiseCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_MAHARASHTRA_DISTRICT_WISE_COUNT,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setDistrictCountData(result);
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  const getApprovedCounts = () => {
    // setLoading(true);    
    fetch(
      service.GET_APPROVED_VISITER,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        if (result[0].message.messageType === 'Success') {
          setApprovedCountData(result);
        }
      })
      .catch(err => {
        if (err) {
          // setDataError(err);
        };
      });
    // setLoading(false);
  };

  useEffect(() => {
    getTotalVisitedCounts();
    getTotalCounts();
    getStateWiseCounts();
    getMaharashtraDistrictWiseCounts();
    getTodaysCounts();
    getApprovedCounts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <Grid>
          <MDBox mb={1.5}>
            <TableContainer component={Paper}>
              <Table id='dvApproved'>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Approved Visiters
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Arrival Date
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Departure Date
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Males
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Females
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Total Members
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Name/Group Leader
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      District
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                      Mobile No.
                    </TableCell>
                  </TableRow>
                  {approvedCountData.map((row) => (
                    <TableRow>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {moment(row.arrivalDate).format("DD/MM/YYYY")} -  {row.arrivalTime}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {moment(row.departureDate).format("DD/MM/YYYY")} - {row.departureTime}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.maleCount}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.femaleCount}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.memberCount}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.firstName} {row.middleName} {row.lastName}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.district} , {row.state} , {row.country}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.mobileNo}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ReactHTMLTableToExcel table="dvApproved" className="info"
              filename="ApprovedVisiters" sheet="Sheet" buttonText="EXPORT TO EXCEL"
            />
          </MDBox>
        </Grid>
        <br />
        <MDBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="people"
                  title="Pending"
                  count={countData.pendingCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1}>
                <ComplexStatisticsCard
                  icon="boy"
                  title="Approved"
                  count={countData.approvedCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Rejected"
                  count={countData.rejectedCount}
                />
              </MDBox>
            </Grid><Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1}>
                <ComplexStatisticsCard
                  color="success"
                  icon="face"
                  title="Visited"
                  count={countData.visitedCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Cancelled"
                  count={countData.cancelledCount}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="money"
                  title="Total"
                  count={countData.totalCount}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={3}>
              <MDBox mb={1.5}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                          Todays Count
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          Room
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          Count
                        </TableCell>
                      </TableRow>
                      {todayCountData.map((row) => (
                        <TableRow>
                          <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                            {row.roomName}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                            {row.approvedCount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox py={3}>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Visited Adults
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.adultMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.adultFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {visitedCountData.adultTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Visited Yuvashakti
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.yuvaMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.yuvaFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {visitedCountData.yuvaTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Visited Balshakti
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Male
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Female
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.balMaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.balFemaleCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {visitedCountData.balTotalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={4} align="center" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            Total Visited
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Adults
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Yuvashakti
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Balshakti
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.adultTotalCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.yuvaTotalCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px' }}>
                            {visitedCountData.balTotalCount}
                          </TableCell>
                          <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {visitedCountData.totalCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Grid>
          <div style={{ fontSize: '14px', fontWeight: 'bold', }}>State Wise Analysis (Visited)</div>
          <MDBox mb={1.5}>
            <TableContainer component={Paper}>
              <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    State
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Adults
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Yuvashakti
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Balshakti
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total Count
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total Donation
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                  <TableCell style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                </TableRow>
                <TableBody>
                  {stateCountData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.state}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.adultMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.adultFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.adultTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.yuvaMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.yuvaFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.yuvaTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.balMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.balFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.balTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.totalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.totalAmount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </Grid>
        <br />
        <Grid>
          <div style={{ fontSize: '14px', fontWeight: 'bold', }}>Districts In Maharashtra (Visited)</div>
          <MDBox mb={1.5}>
            <TableContainer component={Paper}>
              <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Region
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    District
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Adults
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Yuvashakti
                  </TableCell>
                  <TableCell colSpan={3} align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Balshakti
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total Count
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total Donation
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Male
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Female
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                  <TableCell style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                </TableRow>
                <TableBody>
                  {districtCountData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.region}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.district}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.adultMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.adultFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.adultTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.yuvaMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.yuvaFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.yuvaTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.balMaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.balFemaleCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.balTotalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.totalCount}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.totalAmount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
